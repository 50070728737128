const axios = require('axios');
import router from '../../router/index.js';
import store from '../../store';
import * as qs from "qs";

const crudEndpoints = (base) => {
    return {
        getItems: {
            path: "/" + base,
            method: "get",
            requiresAuth: true,
        },
        exportItems: {
            path: "/" + base + "/export",
            method: "get",
            requiresAuth: true,
        },
        getItem: (id) => {
            return {
                path: "/" + base + "/" + id,
                method: "get",
                requiresAuth: true,
            }
        },
        deleteItem: (id) => {
            return {
                path: "/" + base + "/" + id,
                method: "delete",
                requiresAuth: true,
            }
        },
        patchItem: (id) => {
            return {
                path: "/" + base + "/" + id,
                method: "patch",
                requiresAuth: true,
            }
        },
        updateItem: {
            path: "/" + base,
            method: "put",
            requiresAuth: true,
        },
        addItem: {
            path: "/" + base,
            method: "post",
            requiresAuth: true,
        }
    }
}

export const endpoints = {
    auth: {
        login: {
            path: "/login",
            method: "post",
            requiresAuth: false,
        },
        register: {
            path: "/register",
            method: "post",
            requiresAuth: false,
        },
        forgotPassword: {
            path: "/forgot-password",
            method: "post",
            requiresAuth: false,
        },
        validateToken: (token) => {
            return {
                path: "/validate/" + token,
                method: "get",
                requiresAuth: false,
            }
        },
        createPassword: {
            path: "/validate",
            method: "post",
            requiresAuth: false,
        },
        me: {
            path: "/me",
            method: "get",
            requiresAuth: true,
        },
        meUpdate: {
            path: "/me",
            method: "post",
            requiresAuth: true,
        },
        mePassword: {
            path: "/me/password",
            method: "post",
            requiresAuth: true,
        }
    },
    config: {
        update: {
            path: "/config",
            method: "get",
            requiresAuth: true,
        }
    },
    uploads: {
        ...crudEndpoints("uploads"),
        getItem: (id, size) => {
            return {
                path: "/uploads/" + id + "/" + size,
                method: "get",
                requiresAuth: true,
            }
        },
        createItem: {
                path: "/uploads/",
                method: "post",
                requiresAuth: true,
        },
    },
    clients: {
        ...crudEndpoints("clients"),
        current(hostname) {
            return {
                path: "/clients/current/",
                method: "get",
                requiresAuth: false,
            }
        }
    },
    clientUsers: {
        ...crudEndpoints("clients/users"),
        getPendingItems: {
            path: "clients/users/pending",
            method: "get",
            requiresAuth: true,
        },
        deletePendingItem: (id) => {
            return {
                path: "clients/users/pending/" + id,
                method: "delete",
                requiresAuth: true,
            }
        },
        deleteItem: (id) => {
            return {
                path: "clients/users/" + id,
                method: "delete",
                requiresAuth: true,
            }
        },
        impersonate: (id) => {
            return {
                path: "clients/users/" + id + "/impersonate",
                method: "get",
                requiresAuth: true,
            }
        },
        oneTimeToken: (token) => {
            return {
                path: "clients/one-time-token/" + token,
                method: "get",
                requiresAuth: true,
            }
        },
    },
    organisations: {
        ...crudEndpoints("organisations"),
        link: (accountCode) => {
            return {
                path: "/organisations/link/" + accountCode,
                method: "get",
                requiresAuth: true,
            }
        },
        unlink: (accountCode) => {
            return {
                path: "/organisations/link/" + accountCode,
                method: "delete",
                requiresAuth: true,
            }
        },
    },
    users: {
        ...crudEndpoints("users"),
        getPendingItems: {
            path: "/users/pending",
            method: "get",
            requiresAuth: true,
        },
        deletePendingItem: (id) => {
            return {
                path: "/users/pending/" + id,
                method: "delete",
                requiresAuth: true,
            }
        },
        deleteItem: (id) => {
            return {
                path: "/users/" + id,
                method: "delete",
                requiresAuth: true,
            }
        },
        impersonate: (id) => {
            return {
                path: "/users/" + id + "/impersonate",
                method: "get",
                requiresAuth: true,
            }
        },
    },
    assetMeta: {
        ...crudEndpoints("assets/assetMeta")
    },
    batches: {
        ...crudEndpoints("batches")
    },
    assetTypes: {
        ...crudEndpoints("assetTypes"),
        exportTemplate: (id,blank = false) => {
            return {
                path: "/assetTypes/export-template/" + id+ ( blank ? "/true" : ""),
                method: "get",
                requiresAuth: true,
            }
        },
        import: (id) => {
            return {
                path: "/assetTypes/import/" + id,
                method: "post",
                requiresAuth: true,
            }
        },
    },
    additionalFields: {
        ...crudEndpoints("additionalFields")
    },
    additionalFieldValues: {
        ...crudEndpoints("additionalFieldValues")
    },
    assets: {
        ...crudEndpoints("assets")
    },
    sites: {
        ...crudEndpoints("sites"),
        getSitesForOrganisation: (id) => {
            return {
                path: "/sites/list/" + id,
                method: "get",
                requiresAuth: true,
            }
        },
    },
    checks: {
        ...crudEndpoints("checks"),
        exportTemplate: (id,blank = false) => {
            return {
                path: "/checks/export-template/" + id+ ( blank ? "/true" : ""),
                method: "get",
                requiresAuth: true,
            }
        },
        importTasks: (id) => {
            return {
                path: "/checks/import/" + id,
                method: "post",
                requiresAuth: true,
            }
        },
    },
    completedChecks: {
        ...crudEndpoints("assetCompletedChecks"),
    },
    sectors: {
        ...crudEndpoints("sectors"),
    },
    checksDue: {
        ...crudEndpoints("checksDue"),
    },
    tags: {
        upload: {
            path: "/tag-upload",
            method: "post",
            requiresAuth: true,
        },
        delete: {
            path: "/tag-delete",
            method: "post",
            requiresAuth: true,
        },
        exportBatch: (id) => {
            return {
                path: "/batches/" + id + "/export",
                method: "get",
                requiresAuth: true,
            }
        },
    }
}
export const httpRequest = async (endpoint, data) => {

    var headers = {
        Accept: 'applicaton/json',
        ClientHost: window.location.hostname,

    };
    if (endpoint.requiresAuth) {
        var impersonatingToken = localStorage.getItem('impersonatingAccessToken') || null;
        var accessToken = localStorage.getItem('accessToken') || null;
        var currentOrganisationId = router.history.current.params != null ? router.history.current.params.currentOrganisationId : null;
        if (currentOrganisationId !== null) {
            headers.organisationId = currentOrganisationId;
        }
        // try {
        //     jwt.verify(accessToken, "-----BEGIN RSA PUBLIC KEY-----" + process.env.VUE_APP_PK + "-----END RSA PUBLIC KEY-----");
        //console.log("IMPERSONATING IS",impersonatingToken);
        if(impersonatingToken != null && impersonatingToken.length > 10) {
            headers.Authorization = "Bearer " + impersonatingToken;
        } else {
            headers.Authorization = "Bearer " + accessToken;
        }
        // } catch (e) {
        //
        // }
    }

    //console.log("UPLOAD DATA ", data);

    try {
        let response = await axios({
            method: endpoint.method,
            baseURL: process.env.VUE_APP_ENDPOINT,
            url: endpoint.path,
            data: endpoint.method === 'post' || endpoint.method === 'put' ? data : null,
            params: endpoint.method === 'get' ? data : null,
            paramsSerializer: params => {
                return qs.stringify(params)
            },
            headers: headers
        });
        console.log("HTTPREQUEST", endpoint.method + ' - ' + endpoint.path);
        console.log("HTTPREQUEST", response);
        console.log("HTTPREQUESTDATA", data);
        if (response.headers['user-message']) {
            store.dispatch('alerts/setUserMessage', response.headers['user-message']);
        }
        return response;
    } catch (e) {
        console.log("HTTPREQUESTERROR", e.response);

        //CHECK IF IS 401
        if (e.response.status === 401) {
            store.dispatch('auth/logout');
        }

        throw e;
    }
};

