<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div
      class="px-4"
  >
    <h2 class="mb-3">{{ accountCode == null ? 'Link new customer' : 'Unlink customer' }}</h2>
    <v-form class="pb-4" autocomplete="off" :disabled="accountCode != null">

      <v-text-field
          persistent-hint
          hint="6 character account code obtained from the customer"
          label="Customer account code"
          v-model="accountCodeInput"
          :error="errors.accountCode != null"
          :error-messages="errors.accountCode"
      />

      <global-error-inline :errors="errors"></global-error-inline>

      <global-error-inline :errors="errors"/>

      <v-btn :loading="loading"
             @click="saveAction" :color="accountCode == null ? 'success' : 'error'" depressed dark>
        {{ accountCode == null ? 'Link customer' : 'Unlink customer' }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import {mapGetters} from "vuex";
import ApiSelectBox from "../../../components/ApiSelectBox";
import GlobalErrorInline from "../../../components/GlobalErrorInline";
import {endpoints, httpRequest} from "@/store/network/amtag.client";
import emitter from "@/utils/emitter";

export default {
  name: "OrganisationLink",
  components: {GlobalErrorInline, ApiSelectBox, FormLoader},
  data() {
    return {
      loading: false,
      errors: {},
      accountCodeInput: null,
    }
  },
  created() {
    this.accountCodeInput = this.accountCode;
  },
  props: {
    accountCode: {
      type: String,
      default() {
        return null;
      }
    },
    organisationId: null,
  },
  computed: {
    ...mapGetters('users', ['item']),
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'currentOrganisationId']),
  },
  methods: {
    async saveAction() {
      this.loading = true;
      try {
        var endpoint = this.accountCode == null ? endpoints.organisations.link(this.accountCodeInput) : endpoints.organisations.unlink(this.accountCode);
        let res =  await httpRequest(endpoint, {});
        emitter.emit('organisationLinkUpdated', res.data);
      } catch (e) {
        console.log("error", e);
        console.log("error data", e.response.data);
        this.errors = e.response.data;
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
