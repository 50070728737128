import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "../views/portal/Dashboard";
import PortalIndex from "../views/portal/PortalIndex";
import Login from "../views/Login";
import _ from 'lodash';
import Organisations from "../views/portal/organisations/Organisations";
import Users from "../views/portal/users/Users";
import OrganisationAdd from "../views/portal/organisations/OrganisationAdd";
import OrganisationLink from "../views/portal/organisations/OrganisationLink";
import UserAdd from "../views/portal/users/UserAdd";
import ValidateEmail from "../views/ValidateEmail";
import Sites from "../views/portal/sites/Sites";
import SiteAdd from "../views/portal/sites/SiteAdd";
import AssetTypes from "../views/portal/asset-types/AssetTypes";
import AssetTypeAdd from "../views/portal/asset-types/AssetTypeAdd";
import Assets from "../views/portal/assets/Assets";
import TagManagement from "../views/portal/tag-management/TagManagement";
import YourProfile from "../views/portal/YourProfile";
import OrganisationSettings from "../views/portal/OrganisationSettings";
import OrganisationSelect from "../views/OrganisationSelect";
import AssetDetail from "../views/portal/assets/AssetDetail";
import ForgotPassword from "../views/ForgotPassword";
import OrganisationCreate from "../views/OrganisationCreate";
import AssetEdit from "../views/portal/assets/AssetEdit";
import CompletedTasks from "@/views/portal/completed-tasks/CompletedTasks";
import TaskAdd from "@/views/portal/tasks/TaskAdd";
import Tasks from "@/views/portal/tasks/Tasks";
import AssetCheckTimeline from "@/views/portal/assets/AssetCheckTimeline";
import SectorAdd from "@/views/portal/sectors/SectorAdd";
import BatchAdd from "@/views/portal/tag-management/BatchAdd.vue";
import ManufacturerAdd from "@/views/portal/manufacturers/ManufacturerAdd.vue";
import ClientSettings from "@/views/portal/ClientSettings.vue";
import ClientUserAdd from "@/views/portal/clients/users/ClientUserAdd.vue";
import ClientUsers from "@/views/portal/clients/users/ClientUsers.vue";
import ClientEdit from "@/views/portal/clients/ClientEdit.vue";
import Clients from "@/views/portal/clients/Clients.vue";
import TasksImport from "@/views/portal/tasks/TasksImport.vue";
import AssetNoteEdit from "@/views/portal/assets/AssetNoteEdit.vue";
import TaskPlanner from "@/views/portal/task-planner/TaskPlanner.vue";
import AssetTypesImport from "@/views/portal/asset-types/AssetTypesImport.vue";
import Sectors from "@/views/portal/sectors/Sectors.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '',
        name: 'Home',
        redirect: '/login'
    },
    {
        path: '/logout',
        name: 'Logout',
        redirect: '/login',

    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Login
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
    },
    {
        path: '/validate/:token',
        name: 'ValidateEmail',
        component: ValidateEmail,
        props: true,
    },
    {
        path: '/select-organisation',
        name: 'OrganisationSelect',
        component: OrganisationSelect,
        meta: {
            guards: [
                'authenticated', 'multiOrg'
            ]
        }
    },
    {
        path: '/create-organisation',
        name: 'OrganisationCreate',
        component: OrganisationCreate,
        meta: {
            guards: [
                'authenticated'
            ]
        }
    },

    {
        path: '/portal/:currentOrganisationId',

        component: PortalIndex,
        meta: {
            guards: [
                'authenticated', 'orgSelection'
            ]
        },
        children: [
            {
                path: '',
                name: 'Portal',
                component: Dashboard,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
            },
            {
                path: 'clients',
                name: 'Clients',
                component: Clients,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
            },
            {
                path: 'clients/add',
                name: 'ClientAdd',
                component: ClientEdit,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
            },
            {
                path: 'clients/:clientId',
                name: 'ClientEdit',
                props: true,
                component: ClientEdit,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
            },
            {
                path: 'clients/:clientId/delete',
                name: 'ClientDelete',
                props: true,
                component: ClientEdit,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
            },
            {
                path: 'clients/:clientId/users',
                name: 'ClientUsers',
                props: true,
                component: ClientUsers,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
                children: [
                    {
                        path: 'add',
                        name: 'ClientUserAdd',
                        component: ClientUserAdd,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection', 'userAdminRole'
                            ]
                        }
                    },
                    {
                        path: ':userId',
                        name: 'ClientUserEdit',
                        component: ClientUserAdd,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection', 'userAdminRole'
                            ]
                        }
                    },
                    {
                        path: ':userId',
                        name: 'ClientUserDelete',
                        component: ClientUserAdd,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection', 'userAdminRole'
                            ]
                        }
                    },
                ]
            },
            {
                path: 'client-settings',
                name: 'ClientSettings',
                component: ClientSettings,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
            },
            {
                path: 'assets',
                name: 'Assets',
                component: Assets,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
            },
            {
                path: 'assets/:assetId',
                name: 'AssetDetail',
                component: AssetDetail,
                props: true,
                children: [
                    {
                        props: true,
                        path: 'timeline/:checkId',
                        name: 'AssetCheckTimeline',
                        component: AssetCheckTimeline,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                    {
                        props: true,
                        path: 'note',
                        name: 'AssetNote',
                        component: AssetNoteEdit,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    }
                ]
            },
            {
                props: true,
                path: 'assets/:assetId/edit',
                name: 'AssetEdit',
                component: AssetEdit,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                }
            },
            {
                props: true,
                path: 'assets/:assetId/delete',
                name: 'AssetDelete',
                component: AssetEdit,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                }
            },
            {
                path: 'asset-types',
                name: 'AssetTypes',
                component: AssetTypes,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
            },
            {
                path: 'asset-types-import',
                name: 'AssetTypesImport',
                component: AssetTypesImport,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
            },
            {
                path: 'asset-types/add',
                name: 'AssetTypeAdd',
                component: AssetTypeAdd,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                }
            },
            {
                path: 'asset-types/:assetTypeId',
                name: 'AssetTypeEdit',
                component: AssetTypeAdd,
                props: true,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                }
            },
            {
                path: 'asset-types/:assetTypeId/delete',
                name: 'AssetTypeDelete',
                component: AssetTypeAdd,
                props: true,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                }
            },
            {
                path: 'asset-types/:assetTypeId/view',
                name: 'AssetTypeView',
                component: AssetTypeAdd,
                props: true,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                }
            },
            {
                path: 'completed-tasks',
                name: 'CompletedTasks',
                component: CompletedTasks,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
            },
            {
                path: 'task-planner',
                name: 'TaskPlanner',
                component: TaskPlanner,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
            },
            {
                path: 'tasks-import',
                name: 'TasksImport',
                component: TasksImport,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
            },
            {
                path: 'tasks',
                name: 'Tasks',
                component: Tasks,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
                children: [
                    {
                        path: 'add',
                        name: 'TaskAdd',
                        component: TaskAdd,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                    {
                        path: ':taskId',
                        name: 'TaskEdit',
                        component: TaskAdd,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                    {
                        path: ':taskId/delete',
                        name: 'TaskDelete',
                        component: TaskAdd,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                    {
                        path: ':taskId/view',
                        name: 'TaskView',
                        component: TaskAdd,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                ]
            },
            {
                path: 'sectors',
                name: 'Sectors',
                component: Sectors,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection', 'userAdminRole'
                    ]
                },
                children: [
                    {
                        path: 'sectors',
                        name: 'SectorAdd',
                        component: SectorAdd,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                    {
                        props: true,
                        path: 'sectors/:sectorId',
                        name: 'SectorEdit',
                        component: SectorAdd,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                    {
                        props: true,
                        path: 'sectors/:sectorId',
                        name: 'SectorDelete',
                        component: SectorAdd,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                ]
            },
            {
                path: 'users',
                name: 'Users',
                component: Users,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection', 'userAdminRole'
                    ]
                },
                children: [
                    {
                        path: 'add',
                        name: 'UserAdd',
                        component: UserAdd,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection', 'userAdminRole'
                            ]
                        }
                    },
                    {
                        path: ':userId',
                        name: 'UserEdit',
                        component: UserAdd,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection', 'userAdminRole'
                            ]
                        }
                    },
                ]
            },
            {
                path: 'sites-and-zones',
                name: 'SitesAndZones',
                component: Sites,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection',
                    ]
                },
                children: [
                    {
                        path: 'add/:organisationId/:parentId?',
                        name: 'SiteAdd',
                        component: SiteAdd,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection', 'adminRole'
                            ]
                        }
                    },
                    {
                        path: ':siteId',
                        name: 'SiteEdit',
                        component: SiteAdd,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection', 'adminRole'
                            ]
                        }
                    },
                    {
                        path: ':siteId/delete',
                        name: 'SiteDelete',
                        component: SiteAdd,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection', 'adminRole'
                            ]
                        }
                    },
                ]
            },
            {
                path: 'organisations',
                name: 'Organisations',
                component: Organisations,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
                children: [
                    {
                        path: 'add',
                        name: 'OrganisationAdd',
                        component: OrganisationAdd,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },{
                        path: 'link/:accountCode?',
                        name: 'OrganisationLink',
                        component: OrganisationLink,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                    {
                        path: ':organisationId',
                        name: 'OrganisationEdit',
                        component: OrganisationAdd,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                    {
                        path: ':organisationId/delete',
                        name: 'OrganisationDelete',
                        component: OrganisationAdd,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                    {
                        path: ':organisationId/view',
                        name: 'OrganisationView',
                        component: OrganisationAdd,
                        props: true,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                ]
            },
            {
                path: 'tag-management',
                name: 'TagManagement',
                component: TagManagement,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
                children: [
                    {
                        props: true,
                        path: 'edit/:batchId',
                        name: 'BatchEdit',
                        component: BatchAdd,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                    {
                        props: true,
                        path: 'delete/:batchId',
                        name: 'BatchDelete',
                        component: BatchAdd,
                        meta: {
                            dialog: true,
                            guards: [
                                'authenticated', 'orgSelection'
                            ]
                        }
                    },
                ]
            },
            {
                path: 'your-profile',
                name: 'YourProfile',
                component: YourProfile,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection'
                    ]
                },
            },
            {
                path: 'organisation-settings',
                name: 'OrganisationSettings',
                component: OrganisationSettings,
                meta: {
                    guards: [
                        'authenticated', 'orgSelection', 'adminRole'
                    ]
                },
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.name === 'Logout') {
        router.app.$store.dispatch('auth/logout').then(() => {
            router.app.$store.dispatch('alerts/setUserMessage', 'You have been logged out');
        });
    }
    if (to.matched.some(record => _.includes(record.meta.guards, 'authenticated'))) {
        if (to.params.currentOrganisationId && to.params.currentOrganisationId !== router.app.$store.getters['auth/currentOrganisationId']) {
            console.log("ORG ID SETTING", to.params.currentOrganisationId);
            await router.app.$store.dispatch("auth/setOrganisation", to.params.currentOrganisationId);
        }
        if (!router.app.$store.getters['auth/authenticated']) {
            next({
                name: 'Login',
                params: {nextUrl: to.fullPath}
            });
            return;
        }
        if (to.matched.some(record => _.includes(record.meta.guards, 'multiOrg'))) {
            if (!router.app.$store.getters['auth/multiOrg']) {
                await next({
                    name: 'Login',
                    params: {nextUrl: to.fullPath}
                });
                return;
            }
        }
        if (to.matched.some(record => _.includes(record.meta.guards, 'userAdminRole'))) {
            if (!router.app.$store.getters['auth/isUserAdmin']) {

                await next({
                    name: 'Portal',
                    params: {currentOrganisationId: to.params.currentOrganisationId}
                });
                router.app.$store.dispatch("alerts/setUserMessage", "You do not have the correct permissions to access that page");
                return;
            }
        }
        if (to.matched.some(record => _.includes(record.meta.guards, 'adminRole'))) {
            if (!router.app.$store.getters['auth/isAdmin']) {

                await next({
                    name: 'Portal',
                    params: {currentOrganisationId: to.params.currentOrganisationId}
                });
                router.app.$store.dispatch("alerts/setUserMessage", "You do not have the correct permissions to access that page");
                return;
            }
        }
        if (to.matched.some(record => _.includes(record.meta.guards, 'clientAdminRole'))) {
            // if(!router.app.$store.getters['auth/systemAdmin'] && !router.app.$store.getters['auth/clientAdmin']) {
            //
            //     await next({
            //         name: 'Portal',
            //         params: {currentOrganisationId : to.params.currentOrganisationId}
            //     });
            //     router.app.$store.dispatch("alerts/setUserMessage","You do not have the correct permissions to access that page");
            //     return;
            // }
        }
        if (to.matched.some(record => _.includes(record.meta.guards, 'orgSelection'))) {
            var org = router.app.$store.getters['auth/organisation'];
            console.log("CURRENT ORG IS ", org);
            if (!router.app.$store.getters['auth/clientAdmin'] && router.app.$store.getters['auth/organisation'] == null) {
                console.log("FAILED IN HERE");
                next({
                    name: 'OrganisationSelect',
                });
                return;
            }
        }
        next()
    } else {
        next()
    }
});

export default router
